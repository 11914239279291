
@font-face {
  font-family: Cloud-Bold;
  src: url("/public/fonts/Cloud-Bold.otf") format('truetype');
}
@font-face {
  font-family: Cloud-Light;
  src: url("/public/fonts/Cloud-Light.ttf") format('truetype');
}
@font-face {
  font-family: DB_Adman_X;
  src: url("/public/fonts/DB_Adman_X.ttf") format('truetype');
}

/* font-family: "Mitr", sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* new code */
.border-red{
  border-top: solid 3px red;
}

.head-title{
  font-family: Cloud-Bold;
}

.head-title-box{
  padding-top: 10px;
  text-align: center;
}
.head-title-big{
  font-size: 44px;
  letter-spacing: -1px;
}
.head-title{
  font-size: 32px;
  line-height: 42px;
  color: #212428;
}
.list-banner{
  padding-bottom: 15px;
  text-align: center;
}
.copy-right{
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  font-family: "Mitr", sans-serif; 
}
.copy-right-container{
  padding-top: 15px;
  padding-bottom: 15px;
}
.list-banner-img{
  border-radius: 19px;
}
.list-banner:hover{
  opacity: 0.6;
  transition: 0.3s;
}


/* Back to top */
.back-to-top {
  position: fixed;
  bottom: 82px;
  right: 20px;
  z-index: 1000;
}

.back-to-top-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 7px 16px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.459);
  transition: background-color 0.3s;
}

.back-to-top-button:hover {
  background-color: #000000e1;
}
/* end */


/* Promotion page */
.detail-promotion-card{
  padding-bottom: 3px;
  justify-content: center;
  display: flex;
}
.card-title{
  text-align: center;
  border-bottom: 1px solid #e1dcdc;
  margin-bottom: 0px;
}
.card-title-text{
  margin-bottom: 0px;
  padding: 17px 0 17px 0;
  background: #FAFAFA;
  font-family: Cloud-Light;
  color: #333333;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 26px;
}
.banner-promotion-img{
  padding-bottom: 15px;
  text-align: center;
}

.price-text{
  font-family: DB_Adman_X;
  font-size: 28px;
}
.card-text-detail{
  font-family: "Mitr", sans-serif; 
  font-size: 19px;
  line-height: 21px;
  font-weight: 300;
}
.limit-product-text{
  font-family: DB_Adman_X;
  font-weight: 300;
  font-size: 18px;
}
.limit-product-width{
  display: flex;
  justify-content: center;
}
.limit-product{
  width: 70%;
}
.condition-table ul>li{
  list-style-type: circle;
  font-family: Cloud-Light;
  font-size: 15px;
}
.condition-table{
  font-family: Cloud-Light;
}
.condition-text{
  font-size: 16px;
}
.condition-box{
  padding-bottom: 75px;
  padding-top: 10px;
}
.condition-width{
  width: 70%;
}
.condition-box{
  display: flex;
  justify-content: center;
}


/* +++++++++++ responsive +++++++++ */

/* มือถือแนวตั้ง */
@media (max-width: 767px) and (orientation: portrait) {
  .logo-banner{
    width: 100% !important;
  }
  .list-banner-img{
    width: 100% !important;
    border-radius: 8px;
  }
  .copy-right{
    font-size: 15px;
  }
  .head-title{
    font-size: 22px;
    line-height: 29px;
  }
  .head-title-big{
    font-size: 28px;
  }
  .card-width-custom{
    width: 100% !important;
  }
  .limit-product{
    width: 100%;
  }
  .condition-width{
    width: 100%;
  }
  .card-text-detail{
    line-height: 21px;
  }
}


/* มือถือแนวนอน 932px */
@media (max-width: 932px) and (orientation: landscape) {
  .logo-banner{
    width: 35% !important;
  }
  .list-banner-img{
    width: 80% !important;
  }
  .list-banner-img {
    border-radius: 10px;
  }
}

/* มือถือแนวนอน 767px */
@media (max-width: 767px) and (orientation: landscape) {
  .logo-banner{
    width: 28% !important;
  }
  .list-banner-img{
    width: 80% !important;
  }
  .list-banner-img {
    border-radius: 10px;
  }
  .card-width-custom{
    width: 80% !important;
  }
  .limit-product{
    width: 80%;
  }
  .condition-width{
    width: 80%;
  }
}

/* จอ ipad แนวตั้ง */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : portrait){
  .list-banner-img {
    border-radius: 8px;
  }
  .logo-banner{
    width: 100% !important;
  }
  .head-title-box{
    padding-top: 35px !important;
  }
  .list-banner-img{
    width: 90% !important;
  }
  .card-width-custom{
    width: 90% !important;
  }
  .limit-product{
    width: 90%;
  }
  .condition-width{
    width: 90%;
  }
}

/* จอ ipad แนวนอน */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape){
  .logo-banner{
    width: 35% !important;
  }
  .list-banner-img{
    width: 90% !important;
  }
  .card-width-custom{
    width: 90% !important;
  }
  .limit-product{
    width: 90%;
  }
  .condition-width{
    width: 90%;
  }
}